import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { Text, Image } from "../Core"
import { Button } from "../Button"

const InsightCard = ({ image, heading, blurb, button, href, className }) => {
  const mainClasses = classNames("insight-card", className)
  return (
    <div className={mainClasses}>
      <Link to={href} className="insight-card__inner clear-decoration">
        <Image useAR={false} publicId={image} />

        <div className="card-content">
          <Text as="h3" className="card-heading" text={heading} />
          {blurb && <Text className="card-p" text={blurb} />}

          {button.href && button.buttonText && (
            <Button
              className={`info-button ${button.appearance}`}
              appearance={button.appearance}
              internal
              href={button.href}
              buttonText={button.buttonText}
            />
          )}
        </div>
      </Link>
    </div>
  )
}

export default InsightCard
