import React, { useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import Accordion from "../components/accordion/Accordion"

// All page links visible in html
const ProcedureSelector = ({ heading, blurb, services, headerTag }) => {
  const [gender, setGender] = useState(null)
  const [stage, setStage] = useState(0)
  const [activeGroup, setActiveGroup] = useState(null)
  const list = [services.women, services.men]

  return (
    <Section zeroBottomMobile>
      <Columns sideColumnsSize={1}>
        <div className="column procedure-query__header">
          <div className="procedure-query__text">
            <Text as={headerTag} text={heading} />
            <Text text={blurb} />
          </div>

          <div className="procedure-query__services">
            {list.map((g, i) => (
              <div
                key={i}
                className={`procedure-query__female-service  ${
                  typeof gender === "number" && gender !== i
                    ? "procedure-query__hide"
                    : ""
                }`}>
                <button
                  aria-label="select gender procedures"
                  onClick={() => {
                    setGender(i)
                    setActiveGroup(g[0].category)
                  }}
                  className="standard-button procedure-query__service-btn">
                  {i === 0 ? "Female" : "Male"}
                </button>
                <div className="relative">
                  <button
                    aria-label={`select ${
                      i === 0 ? "female" : "male"
                    } procedures`}
                    onClick={() => {
                      setGender(i)
                    }}
                    className="procedure-query__figure-btn">
                    <Image
                      publicId={`77P/DEV/mobile-${
                        i === 0 ? "woman" : "man"
                      }-figure-step1.svg`}
                      wrapperClassName={`procedure-query__figure-image ${
                        gender === "female"
                          ? "procedure-query__translucent-figure"
                          : ""
                      }`}
                    />
                  </button>
                  <div
                    className={`procedure-query__figure-category ${
                      gender !== i ? "procedure-query__hide" : ""
                    }`}>
                    {g.map((links, linkIndex) => (
                      <button
                        style={{ display: `` }}
                        key={links.category}
                        aria-label={links.category}
                        className={`procedure-query__category-btn ${
                          activeGroup && activeGroup === links.category
                            ? "procedure-query__active-category-btn"
                            : ""
                        }`}
                        onClick={() => {
                          setStage(2)
                          setActiveGroup(links.category)
                        }}>
                        {links.category}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <div
              className={`procedure-query__links ${
                gender === null ? "procedure-query__hide" : ""
              }`}>
              <button
                className="procedure-query__back-btn"
                onClick={() => {
                  setGender(null)
                  setActiveGroup(null)
                  setStage(0)
                }}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>

              {list.map((items, genderIndex) =>
                items.map(groups => (
                  <div
                    key={groups.category}
                    className={`${
                      genderIndex === gender && activeGroup === groups.category
                        ? ""
                        : "procedure-query__hide"
                    }`}>
                    {groups.groups.map(subGroup => (
                      <Accordion
                        key={subGroup.name}
                        heading={subGroup.name}
                        className={`procedure-query__links-text`}>
                        {subGroup.pages.map(page => (
                          <div
                            className="mt-sm group-page-links"
                            key={page.name}>
                            <WithLink slug={page.href} title={page.name}>
                              <Text
                                style={{ fontWeight: 500 }}
                                as="p"
                                className="px-0"
                                text={page.name}
                              />
                            </WithLink>
                          </div>
                        ))}
                      </Accordion>
                    ))}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </Columns>
    </Section>
  )
}

const WithLink = ({ slug, children, title }) => {
  if (slug) {
    return (
      <Link to={"/" + slug} title={"Learn more about " + title}>
        {children}
      </Link>
    )
  }
  return <>{children}</>
}

export default ProcedureSelector
